import React, { Component } from 'react';
import './FallbackHome.scss';

class FallbackHome extends Component {
  render() {
    return (
        <main className="home-container">
            <section className="home-section fb-home-section">
                <div className="fb-text">
                    Tune into the&nbsp;  
                        <a className="fb-text-link" 
                            href="slack://channel?team=T0T3484H4&id=C0TSMCZL5" 
                            rel="noopener noreferrer">
                            conversation
                        </a>  on Slack or&nbsp; 
                        <a className="fb-text-link" 
                            href="slack://channel?team=T0T3484H4&id=C0TT9124W" 
                            rel="noopener noreferrer">
                            get started
                        </a> DJing with IBM Radio
                </div>
            </section>
        </main>
    )
  }
}

export default FallbackHome
