import React, { Component } from 'react';
import './ErrorConnecting.scss'

class ErrorConnecting extends Component {
  render() {
    return (
      <aside role="alert">
        Could not connect to IBM Radio. Please check your VPN or internet connection.
      </aside>
    )
  }
}

export default ErrorConnecting
