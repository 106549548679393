import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import utils from '../utilities';
import _ from 'underscore';
import './Navigation.scss';

export class Navigation extends Component {
    state = {
        navOpen: false,
        isClosing: false,
        isCurrentlyOpen: false
    }

    toggleNav = () => {

        var ctxt = this;

        if (this.state.navOpen === false) {
            ctxt.setState({navOpen : !ctxt.state.navOpen});   
            ctxt.setState({isCurrentlyOpen : !ctxt.state.isCurrentlyOpen});   
        } else {
            ctxt.setState({isCurrentlyOpen : !ctxt.state.isCurrentlyOpen});   
            const startClose = () => {
                return new Promise(function (resolve, reject) {
                ctxt.setState({isClosing: true});
                resolve();
                });
            }

            const togNav = () => {
                return new Promise(function (resolve, reject) {
                    setTimeout((function () {
                        ctxt.setState({navOpen : !ctxt.state.navOpen}); 
                        resolve();
                    }), 1000)
                });
            }

            const resetClose = () => {
                return new Promise(function (resolve, reject) {
                    ctxt.setState({isClosing: false});
                    resolve();
                });
            }

            startClose().then(togNav).then(resetClose);
        }
    }

    // Check width of document and if necessary, remove month view
    checkWidth = (width) => {
        var w = utils.getDocWidth();
        if (w > width) {
            this.setState({navOpen: false});
            this.setState({isCurrentlyOpen: false});
        }
    }

    componentDidMount() {
        var ctxt = this;
        // On resize, set the appropriate view
        window.addEventListener('resize', _.debounce(function(){
            ctxt.checkWidth(630);
        }, 50, true))
    }

    render() {
        return (
            <div className="navigation-wrapper">
                <nav className="navigation">
                    <button className="navigation-btn" onClick={this.toggleNav}>
                        <div className="icon-container">
                            <div className={ this.state.isCurrentlyOpen ? "icon-canvas icon-navigation open" : "icon-canvas icon-navigation" } >
                                <div className="hamburger"></div>
                            </div>
                        </div>
                    </button>
                    <h1 className='site-title'>
                        <NavLink 
                            exact 
                            to={'/'}
                            className='navigation-item-link' 
                            activeClassName="nav-selected" 
                            onClick={this.state.navOpen ? this.toggleNav : () => {} }
                        >
                        IBM Community Radio
                        </NavLink>
                        { this.props.beta && (
                            <div className="betaTag">beta</div>
                        )}
                    </h1>

                    <ul className={ this.state.navOpen && this.state.isClosing ? "navigation-list open closing" : this.state.navOpen ? "navigation-list open" : "navigation-list" }>
                        { this.state.navOpen ?
                            (<li className='navigation-item navigation-item-home'>
                                <NavLink 
                                    exact 
                                    to={'/'}
                                    className='navigation-item-link' 
                                    activeClassName="nav-selected" 
                                    onClick={this.state.navOpen ? this.toggleNav : () => {} }
                                >
                                    <span className="navigation-item-link-img"><img alt='Currently playing' src='./img/play.svg' /></span>
                                    <span className="navigation-item-link-text">On Now</span>
                                </NavLink>
                            </li>) :
                            (<li className='navigation-item navigation-item-home--alt'>
                                <NavLink 
                                    exact 
                                    to={'/'}
                                    className='navigation-item-link' 
                                    activeClassName="nav-selected" 
                                    onClick={this.state.navOpen ? this.toggleNav : () => {} }
                                >
                                    <span>IBM Community Radio</span>
                                </NavLink>
                            </li>)
                        }
                        <li className='navigation-item navigation-item-calendar'>
                            <NavLink 
                                exact 
                                to={'/calendar'}
                                className='navigation-item-link' 
                                activeClassName="nav-selected"
                                onClick={this.state.navOpen ? this.toggleNav : () => {} }
                            >
                                <span className="navigation-item-link-img"><img alt='View the schedule of events' src='./img/schedule.svg' /></span>
                                <span className="navigation-item-link-text">Calendar</span>
                            </NavLink>
                        </li>
                        <li className='navigation-item navigation-item-tune-in'>
                            <a className='navigation-item-link' href="#" rel="noopener noreferrer">
                                <span className="navigation-item-link-img">
                                    <img alt='Join the conversation' src='./img/chat.svg' />
                                </span>
                                <span className="navigation-item-link-text">On-Demand</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default Navigation
