import React from 'react';
import '../../node_modules/fullcalendar/dist/fullcalendar.min.css';
import './Calendar.scss';
import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment';
import utils from '../utilities';
import 'fullcalendar';
import '../../node_modules/fullcalendar/dist/gcal.min.js';

class Calendar extends React.Component {

  state = {
    firstLoadEvent: false
  }

  componentDidMount() {

    // Initialize calendar, check width, and set correct icons
    const init = () => {

      const ctxt = this;

      $('#calendar').fullCalendar({
        customButtons: {
          navigateBack: {
            text: ' ',
            click: function() {
              $(".site-title .navigation-item-link")[0].click();
            }
          }
        },
        header: {
          left: 'navigateBack, title',
          right: 'prev,next'
        },
        googleCalendarApiKey: 'AIzaSyDQnCOmrKMdMrIJlFLHllCuJ6PFDdC-wPc',
        defaultView: 'listMonth',
        views: {
          listMonth: {
            titleFormat: 'MMM YYYY',
          }
        },
        events: {
          googleCalendarId: 'ibm.radio.ops@gmail.com'
        },
        listDayFormat: 'dddd MMMM Do',
        listDayAltFormat: false,
        timezone: 'local',
        weekends: false,
        fixedWeekCount: false,
        eventClick: function(calEvent, jsEvent) {
          jsEvent.preventDefault();
          // if (calEvent.url) {
          //   window.open(calEvent.url);
          // }
        },
        viewRender: function() {
          // when moving forward or backward, scroll to top of event list
          $("#calendar").scrollTop(0);
        },
        eventAfterAllRender: function() {
          // scroll to current date in calendar
          if (!ctxt.state.firstLoadEvent) {
            // find the current date and the corresponding element in the calendar
            const date = moment().format('YYYY-MM-DD');
            const element = document.querySelector(`.fc-list-heading[data-date="${date}"]`)
            // @todo set the element to the next closest date
            // if an element exists scroll to that element
            if (element) {
              const element_offset = element.offsetTop;
              let scroll_dist = 0;
              // adapt the scroll distance depending on screen size
              if (utils.getDocWidth() < 930) {
                scroll_dist = element_offset - 10
              } else if (utils.getDocWidth() > 930) {
                scroll_dist = element_offset + 15
              }
              // animate scroll to event
              $('#calendar').animate({
                scrollTop: scroll_dist
              }, 1000);
            }
            // only do this the first time the component is mounted
            ctxt.setState({firstLoadEvent: true})
          }
        }
      });

      // Check width of screen to apply correct configuration
      this.checkWidth();

      $( ".fc-icon-right-single-arrow" ).replaceWith( "<span class='calendar-right-btn calendar-btn'><img src='./img/right_arrow.svg'/></span>" );
      $( ".fc-icon-left-single-arrow" ).replaceWith( "<span class='calendar-left-btn calendar-btn'><img src='./img/left_arrow.svg'/></span>" );
      $( ".fc-navigateBack-button.fc-button" ).html("<span class='calendar-btn'><img src='./img/back.svg'/></span>")


      getCalHeight();
    }

    const getCalHeight = () => {
      var h = document.querySelector('.main-content').offsetHeight;
      $('#calendar').fullCalendar('option', 'height', h);
    }
    
    // Run init on load
    window.addEventListener('load', () => {
      init();
      getCalHeight();
    })

    // Capture context so that checkCalWidth can be debounced
    const ctxt = this;
    // On resize, set the appropriate view
    window.addEventListener('resize', _.debounce(function(){
      ctxt.checkWidth();
    }, 200))

    init();
  }

  // Check width of document and if necessary, remove month view
  checkWidth = () => {
    if (utils.getDocWidth() < 700) {
      $('#calendar').fullCalendar('changeView', 'listMonth');
    }
  }

  render () {
    return (
        <div id="calendar" />
    )
  }
}

export default Calendar;
