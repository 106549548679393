import React, { Component } from "react";
import "./Banner.scss";

class Banner extends Component {
  render() {
    return (
      <div className="banner-container">
        <div className="svg-container">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 480 960"
            style={{ enableBackground: "new 0 0 480 960" }}
            xmlSpace="preserve"
          >
            <style type="text/css">
              {`
                    .st0{fill:none;}
                    .st1{opacity:0.15;filter:url(#AI_GaussianBlur_25);}
                    .st2{fill:#1737A2;}
                    .st3{opacity:0.15;filter:url(#AI_GaussianBlur_18_1_);}
                    .st4{fill:url(#_x5F__x5F_LMB_1_);}
                    .st5{opacity:0.1;fill:#1737A2;}
                    .st6{fill:url(#_x5F__x5F_LSB_1_);}
                    .st7{fill:url(#_x5F__x5F_RLB_1_);}
                    .st8{fill:url(#_x5F__x5F_LLB_1_);}
                    `}
            </style>
            <rect className="st0" width="480" height="960" />
            <g id="main">
              <linearGradient
                id="_x5F__x5F_RLB_1_"
                gradientUnits="userSpaceOnUse"
                x1="273.6381"
                y1="423.6181"
                x2="525.5255"
                y2="240.6112"
                gradientTransform="matrix(1.3615 -1.3615 0.7071 0.7071 -330.5649 391.8136)"
              >
                <stop offset="0" style={{ stopColor: "#A970FF" }} />
                <stop offset="0.1398" style={{ stopColor: "#A470FF" }} />
                <stop offset="0.2948" style={{ stopColor: "#9771FF" }} />
                <stop offset="0.4572" style={{ stopColor: "#8171FF" }} />
                <stop offset="0.6247" style={{ stopColor: "#6273FF" }} />
                <stop offset="0.7961" style={{ stopColor: "#3A74FF" }} />
                <stop offset="0.9685" style={{ stopColor: "#0A76FF" }} />
                <stop offset="1" style={{ stopColor: "#0076FF" }} />
              </linearGradient>
              <linearGradient
                id="_x5F__x5F_LMB_1_"
                gradientUnits="userSpaceOnUse"
                x1="-433.67"
                y1="956.3782"
                x2="-11.1621"
                y2="956.3782"
                gradientTransform="matrix(1.0086 -1.0086 0.7071 0.7071 -392.5272 149.8207)"
              >
                <stop offset="0" style={{ stopColor: "#20D5D2" }} />
                <stop offset="0.1098" style={{ stopColor: "#3AC2DB" }} />
                <stop offset="0.2999" style={{ stopColor: "#62A4E8" }} />
                <stop offset="0.4876" style={{ stopColor: "#818EF2" }} />
                <stop offset="0.6696" style={{ stopColor: "#977DF9" }} />
                <stop offset="0.8433" style={{ stopColor: "#A473FE" }} />
                <stop offset="1" style={{ stopColor: "#A970FF" }} />
              </linearGradient>
              <linearGradient
                id="_x5F__x5F_LLB_1_"
                gradientUnits="userSpaceOnUse"
                x1="-149.63"
                y1="709.3867"
                x2="106.1196"
                y2="523.5737"
                gradientTransform="matrix(1.7097 -1.7097 0.7071 0.7071 -375.4818 436.7304)"
              >
                <stop offset="0" style={{ stopColor: "#20D5D2" }} />
                <stop offset="0.1098" style={{ stopColor: "#3AC2DB" }} />
                <stop offset="0.2999" style={{ stopColor: "#62A4E8" }} />
                <stop offset="0.4876" style={{ stopColor: "#818EF2" }} />
                <stop offset="0.6696" style={{ stopColor: "#977DF9" }} />
                <stop offset="0.8433" style={{ stopColor: "#A473FE" }} />
                <stop offset="1" style={{ stopColor: "#A970FF" }} />
              </linearGradient>
              <linearGradient
                id="_x5F__x5F_LSB_1_"
                gradientUnits="userSpaceOnUse"
                x1="-239.9337"
                y1="726.4191"
                x2="-4.8151"
                y2="726.4191"
                gradientTransform="matrix(1.4463 -1.4463 0.5034 0.5034 -239.6886 288.7062)"
              >
                <stop offset="0" style={{ stopColor: "#20D5D2" }} />
                <stop offset="0.1098" style={{ stopColor: "#3AC2DB" }} />
                <stop offset="0.2999" style={{ stopColor: "#62A4E8" }} />
                <stop offset="0.4876" style={{ stopColor: "#818EF2" }} />
                <stop offset="0.6696" style={{ stopColor: "#977DF9" }} />
                <stop offset="0.8433" style={{ stopColor: "#A473FE" }} />
                <stop offset="1" style={{ stopColor: "#A970FF" }} />
              </linearGradient>
              <g id="group-1">
                <path
                  id="_x5F__x5F_RMDb"
                  className="st5"
                  d="M782.2-32L430.5,319.6c-5.4,5.4-14.1,5.4-19.5,0v0c-5.4-5.4-5.4-14.1,0-19.5L762.7-51.5
                    c5.4-5.4,14.1-5.4,19.5,0v0C787.6-46.2,787.6-37.4,782.2-32z"
                />
                <path
                  id="_x5F__x5F_RMDb_x5F_dot"
                  className="st5"
                  d="M398.6,351.6l-17.7,17.7c-4.9,4.9-12.8,4.9-17.7,0l-1.9-1.9
                    c-4.9-4.9-4.9-12.8,0-17.7l17.7-17.7c4.9-4.9,12.8-4.9,17.7,0l1.9,1.9C403.5,338.8,403.5,346.7,398.6,351.6z"
                />
                <path
                  id="_x5F__x5F_LMD"
                  className="st5"
                  d="M199.1,518.3l-324.6,324.6c-12.9,12.9-33.7,12.9-46.6,0h0c-12.9-12.9-12.9-33.7,0-46.6
                    l324.6-324.6c12.9-12.9,33.7-12.9,46.6,0l0,0C212,484.5,212,505.4,199.1,518.3z"
                />
                <path
                  id="_x5F__x5F_LMB"
                  className="st4"
                  d="M272.5,876.4l-387.1,387.1c-10.8,10.8-28.3,10.8-39.1,0h0c-10.8-10.8-10.8-28.3,0-39.1
                    l387.1-387.1c10.8-10.8,28.3-10.8,39.1,0h0C283.3,848.1,283.3,865.6,272.5,876.4z"
                />

                <path
                  id="_x5F__x5F_RLB"
                  className="st7"
                  d="M679.5-63L302.7,313.8c-23.6,23.6-61.9,23.6-85.6,0l0,0c-23.6-23.6-23.6-61.9,0-85.6
                    L594-148.6c23.6-23.6,61.9-23.6,85.6,0l0,0C703.1-125,703.1-86.7,679.5-63z"
                />
              </g>

              <g id="group-2">
                <path
                  id="_x5F__x5F_LSDb"
                  className="st5"
                  d="M308.2,931.5l-359.3,359.3c-3.3,3.3-8.6,3.3-11.9,0h0c-3.3-3.3-3.3-8.6,0-11.9l359.3-359.3
                        c3.3-3.3,8.6-3.3,11.9,0l0,0C311.4,922.9,311.4,928.2,308.2,931.5z"
                />
                <path
                  id="_x5F__x5F_RSDt"
                  className="st5"
                  d="M596.6-303.1L237.3,56.2c-3.3,3.3-8.6,3.3-11.9,0v0c-3.3-3.3-3.3-8.6,0-11.9L584.8-315
                    c3.3-3.3,8.6-3.3,11.9,0l0,0C599.9-311.7,599.9-306.4,596.6-303.1z"
                />
                <path
                  id="_x5F__x5F_LLB"
                  className="st8"
                  d="M313.6,705.1l-495.1,495.1c-23.6,23.6-61.9,23.6-85.6,0l0,0c-23.6-23.6-23.6-61.9,0-85.6
                    L228,619.5c23.6-23.6,61.9-23.6,85.6,0l0,0C337.2,643.2,337.2,681.5,313.6,705.1z"
                />

                <path
                  id="_x5F__x5F_RMDt"
                  className="st5"
                  d="M591.5-183.6L254.8,153.1c-9.5,9.5-24.9,9.5-34.4,0l0,0c-9.5-9.5-9.5-24.9,0-34.4
                    l336.8-336.8c9.5-9.5,24.9-9.5,34.4,0l0,0C601-208.6,601-193.1,591.5-183.6z"
                />
              </g>

              <g id="group-3">
                <path
                  id="_x5F__x5F_LSDt"
                  className="st5"
                  d="M109.2,454.8l-359.3,359.3c-3.3,3.3-8.6,3.3-11.9,0l0,0c-3.3-3.3-3.3-8.6,0-11.9
                        L97.4,442.9c3.3-3.3,8.6-3.3,11.9,0h0C112.5,446.2,112.5,451.5,109.2,454.8z"
                />

                <path
                  id="_x5F__x5F_RSDb"
                  className="st5"
                  d="M773.1,123.7L413.7,483c-3.3,3.3-8.6,3.3-11.9,0h0c-3.3-3.3-3.3-8.6,0-11.9l359.3-359.3
                        c3.3-3.3,8.6-3.3,11.9,0v0C776.3,115.1,776.3,120.4,773.1,123.7z"
                />
                <path
                  id="_x5F__x5F_LSB"
                  className="st6"
                  d="M119,676.8l-324.6,324.6c-4.3,4.3-11.2,4.3-15.5,0h0c-4.3-4.3-4.3-11.2,0-15.5l324.6-324.6
                    c4.3-4.3,11.2-4.3,15.5,0h0C123.3,665.6,123.3,672.5,119,676.8z"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default Banner;
