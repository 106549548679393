import React, { Component } from 'react';
import moment from 'moment';
import $clamp from 'clamp-js';
import './Home.scss';

export class Home extends Component {

  componentDidMount(){
    // If show title heading is greater than 2 lines, use ellipsis
    var currentShowTitleHeading = document.querySelector('.current-show-title-text');
    $clamp(currentShowTitleHeading, {clamp: 2});
    // If dj/artist test is greater than 1 lines, use ellipsis
    var currentShowArtist = document.querySelector('.current-show-dj-text');
    $clamp(currentShowArtist, {clamp: 1});

    // If Next show title heading is greater than 3 lines, use ellipsis
    var nextShowTitleHeading = document.querySelector('.next-show-title-text');
    $clamp(nextShowTitleHeading, {clamp: 2});
  }

  render() {
    return (
      <main className="home-container">
        <section className="home-section">
            <span className="current-show-header-container">
              <div className="current-show-type">
              {this.props.currentShow.type === 'PREMIERE' ? 
                <span className="current-show-type-text live">LIVE</span> :
                <span className="current-show-type-text">REPLAY</span>
              }
              </div>
              <div className="current-show-details current-show-details--inline">
                    {this.props.currentShow.startTime && this.props.currentShow.type === "PREMIERE" && (
                      <React.Fragment>
                        <div className="current-show-time"> 
                          { moment.duration(this.props.currentShow.runTime*1000)._data.hours > 0 && Math.ceil(moment.duration(this.props.currentShow.runTime*1000)._data.minutes / 15) * 15 > 45 ? 
                            `${moment.duration(this.props.currentShow.runTime*1000)._data.hours + 1}h` : 
                            moment.duration(this.props.currentShow.runTime*1000)._data.hours > 0 ? 
                            Math.ceil(moment.duration(this.props.currentShow.runTime*1000)._data.minutes / 15) * 15 === 0 ?
                            `${moment.duration(this.props.currentShow.runTime*1000)._data.hours}h` :
                            `${moment.duration(this.props.currentShow.runTime*1000)._data.hours}h ${Math.ceil(moment.duration(this.props.currentShow.runTime*1000)._data.minutes / 15) * 15}m` :
                              Math.ceil(moment.duration(this.props.currentShow.runTime*1000)._data.minutes / 15) * 15 > 45 ? '1h' :
                                `${Math.ceil(moment.duration(this.props.currentShow.runTime*1000)._data.minutes / 15) * 15}m`
                          }
                        </div>
                      </React.Fragment>
                    )}
              </div>
            </span>
            <div className="current-show-info">
              <div className="current-show-title">
              {this.props.currentShow.type ? 
                <h2 className="current-show-title-text">{this.props.currentShow.showTitle}</h2> :
                <h2 className="current-show-title-text">Ping Pong</h2>
              }
              </div>

              <div className="current-show-dj">
              {this.props.currentShow.type ? 
                <span className="current-show-dj-text">{this.props.currentShow.dj}</span> :
                <span className="current-show-dj-text">Bradley</span>}
              </div>
            </div>
            <span className="next-live-show-header-container">
                {/* <h4 className="next-live-show-header"> </h4> */}
                <div className="next-live-show-details next-live-show-details--inline">
                <div className="next-live-show-label"> Next Live Show&nbsp; </div>
                  {this.props.nextShow.startTime ? (
                    <React.Fragment>
                      <div className="next-live-show-date"> 
                        { moment(this.props.nextShow.startTime*1000).calendar(null, {
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            nextWeek : 'dddd',
                          })
                        } from&nbsp;
                      </div>
                      <div className="next-live-show-time"> 
                        {moment(this.props.nextShow.startTime*1000).format('h:mm')}
                        &nbsp;-&nbsp;
                        {moment(this.props.nextShow.endTime*1000).format('h:mma')}
                      </div>
                    </React.Fragment>
                  ):(
                    <React.Fragment>
                    <div className="next-live-show-date"> 
                      Tuesday from&nbsp;
                    </div>
                    <div className="next-live-show-time"> 
                      2:00&nbsp;-&nbsp;3:00pm
                    </div>
                  </React.Fragment>
                  )}
                </div>
              </span>
              <div className="next-show-info">
              <div className="next-live-show-title">
                {this.props.nextShow.showTitle ? ( 
                  <span className="next-show-title-text">{this.props.nextShow.showTitle}</span>
                ) : (
                  <span className="next-show-title-text">Title Unavailable</span>
                )}
              </div>
              {this.props.nextShow.showTitle ? ( 
                <span className="next-live-show-dj">{this.props.nextShow.dj}</span> 
              ) : (
                <span className="next-live-show-dj">DJ Unavailable</span> 
              )}
              </div>
        </section>
      </main>
    )
  }
}

export default Home
