import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll } from 'body-scroll-lock';
import 'pace-js';
import 'pace-js/themes/blue/pace-theme-minimal.css';
import Home from './components/Home';
import FallbackHome from './components/FallbackHome';
import Banner from './components/Banner';
import Navigation from './components/Navigation';
import Calendar from './components/Calendar';
import AudioPlayer from './components/AudioPlayer';
import ErrorConnecting from './components/ErrorConnecting';
import './App.scss';

class App extends Component {

  //target elements to persist scrolling on
  mc = null;
  ac = null;

  state = {
    audioSettings: {
      // stream: 'http://ibmradio.net:8000/listen',
      stream: 'https://kcrw.streamguys1.com/kcrw_192k_mp3_on_air',
      autoPlay: true,
      element: {},
      isPlaying: false
    },
    currentShow : {},
    nextShow : {},
    metaData : {},
    shows: [],
    beta: true
  }

  componentDidMount() {

    var detectViewportHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', () => {
      detectViewportHeight();
    })

    detectViewportHeight();

    // unlock scroll for .main-content
    this.mc = document.querySelector('.main-content');
    this.ac = document.querySelector('.audio-player');
    disableBodyScroll(this.mc);
    disableBodyScroll(this.ac);

    const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
      if (n === 1) throw Error;
      return fetch_retry(url, options, n - 1);
    });

    // META DATA SERVICE

    // Create an object with all of properties returned by metadata service
    let currentShow = this.state.currentShow;

    const currentShowType = "PREMIERE";
    currentShow.type = currentShowType;
    const currentDJ = "Maui and Alisha"
    currentShow.dj = currentDJ;
    const currentShowTitle = "Rock Steady Power Hour"
    currentShow.showTitle = currentShowTitle;
    const currentSongTitle = "";
    currentShow.songTitle = currentSongTitle;
    const currentShowStartTime = 100000;
    currentShow.startTime = currentShowStartTime;
    const currentShowEndTime = 107200;
    currentShow.endTime = currentShowEndTime;
    const currentShowDuration = 7200;
    currentShow.runTime = currentShowDuration;

    // Set filled object to state.currentShow
    this.setState({ currentShow });

    let nextShow = this.state.nextShow;
    const nextDJ = "Milena";
    nextShow.dj = nextDJ;
    const nextShowTitle = "DJDC Presents";
    nextShow.showTitle = nextShowTitle;
    this.setState({nextShow});

    // const getMetadata = () => {
    //   fetch_retry("http://mini.ibmradio.net:3000/metadata", {}, 5)
    //   .then( res => res.json())
    //   .then(data => {
    //     // Set state.metadata
    //     const metaData = data;
    //     this.setState({ metaData });
    //     return metaData;
    //   })
    //   .then(metaData => {
    //     // Create an object with all of properties returned by metadata service
    //     let currentShow = this.state.currentShow;

    //     const currentShowType = metaData.mode ? metaData.mode : "";
    //     currentShow.type = currentShowType;
    //     const currentDJ = metaData.metadata.artist ? metaData.metadata.artist : "";
    //     currentShow.dj = currentDJ;
    //     const currentShowTitle = metaData.metadata.title ? metaData.metadata.title : "";
    //     currentShow.showTitle = currentShowTitle;
    //     const currentSongTitle = metaData.metadata.dynamic ? metaData.metadata.dynamic : "";
    //     currentShow.songTitle = currentSongTitle;
    //     const currentShowStartTime = metaData.metadata.start ? metaData.metadata.start : "";
    //     currentShow.startTime = currentShowStartTime;
    //     const currentShowEndTime = metaData.metadata.end ? metaData.metadata.end : "";
    //     currentShow.endTime = currentShowEndTime;
    //     const currentShowDuration = metaData.metadata.duration ? metaData.metadata.duration : 0;
    //     currentShow.runTime = currentShowDuration;

    //     // Set filled object to state.currentShow
    //     this.setState({ currentShow });
    //   })
    //   .catch(err => console.log(err));
    // }

    // CALENDAR DATA

    const getShowData = () => {
      // fetch_retry("http://mini.ibmradio.net:3000/shows", {}, 5)
      // .then( res => res.json())
      // .then(data => {
      //   const shows = data;
      //   this.setState({ shows });

      //   // IF THERE IS A LIVE SHOW, SET THE DATA FOR IT
        
      //   // check if there is a show in the shows array that's currently on
      //   const theCurrentShow = shows.filter( (show) => {
      //     return Date.now() >= show.start*1000 && Date.now() < show.end*1000;
      //   });

      //   const currentShowObj = theCurrentShow[0];
      //   let currentShowData = {};
      //   let nextShow = {};

      //   // if there is no live show
      //   if (isEmpty(currentShowObj)) {
      //     // if there is no live show, fill out an object with the first item in the shows array
      //     if (shows[0]) {
      //       nextShow = {
      //         showTitle : shows[0].title,
      //         dj : shows[0].dj,
      //         description : shows[0].liner,
      //         startTime : shows[0].start,
      //         endTime : shows[0].end,
      //         // TODO
      //         runTime: ""
      //       }
      //     } else {
      //       nextShow = {
      //         showTitle : "No Available Shows",
      //         dj : "No Available DJ",
      //         description : "No Available Description",
      //         startTime : "0:00",
      //         endTime : "0:00",
      //         // TODO
      //         runTime: ""
      //       };
      //     }
      //     // if there is a live show
      //   } else {

      //     // if there is a live show, fill out an object with its data
      //     if (currentShowObj) {
      //       currentShowData.description = currentShowObj.liner ? currentShowObj.liner : "";
      //       currentShowData.startTime = currentShowObj.start ? currentShowObj.start : 0;
      //       currentShowData.endTime = currentShowObj.end ? currentShowObj.end : 0;
      //     }

      //     // fill an object with data from the 2nd entry in the shows array (one after the current show)
      //     if (shows[1]) {
      //       nextShow = {
      //         showTitle : shows[1].title,
      //         dj : shows[1].dj,
      //         description : shows[1].liner,
      //         startTime : shows[1].start,
      //         endTime : shows[1].end,
      //         // TODO
      //         runTime: ""
      //       }
      //     } else {
      //       nextShow = {
      //         showTitle : "No Available Shows",
      //         dj : "No Available DJ",
      //         description : "No Available Description",
      //         startTime : "0:00",
      //         endTime : "0:00",
      //         // TODO
      //         runTime: ""
      //       };
      //     }

      //   }
        
      //   function isEmpty(obj) {
      //     for(var key in obj) {
      //         if(obj.hasOwnProperty(key))
      //             return false;
      //     }
      //     return true;
      //   }
      //   // Merge the new properties into an object with the existing currentShow data
      //   const currentShow = Object.assign(this.state.currentShow, currentShowData);
      //   this.setState({currentShow});
      //   this.setState({nextShow});
      // })
      // .catch(err => console.log(err));
    }

  //  //every 10 seconds get the latest show that's playing
  //   setInterval(() => {
  //     getMetadata();
  //   }, 20000)

  //   //every minute get the upcoming show data
  //   setInterval(() => {
  //     getShowData();
  //   }, 20000)

    // getMetadata();
    // getShowData();

  }

  toggleStream = () => {
    if (this.state.audioSettings.element.paused) {
      this.state.audioSettings.element.play();
      const as = this.state.audioSettings;
      as.isPlaying = true;
      this.setState({audioSettings: as});
    } else {
      this.state.audioSettings.element.pause();
      const as = this.state.audioSettings;
      as.isPlaying = false;
      this.setState({audioSettings: as});
    }
  }

  setPlayState = (s) => {
    const as = this.state.audioSettings;
    if (s === "play") {
      as.isPlaying = false;
      this.setState({audioSettings: as})
    } else if (s === "pause") {
      as.isPlaying = true;
      this.setState({audioSettings: as})
    }
  }

  render() {
    return (
        <React.Fragment>
          <Router>
            <div className="App">
              {/* { Object.getOwnPropertyNames(this.state.currentShow).length === 0 && (
                <ErrorConnecting />
              )} */}
              <div className="non-error-content">
                <Banner />
                <div className="non-banner-content">
                  <div className="App-content">
                  <Navigation {...this.state} />
                  <main className="main-content">
                    <Route exact path="/" >
                        {({ match }) => (
                          <CSSTransition
                            in={match != null}
                            timeout={1000}
                            classNames="page"
                            unmountOnExit
                          >
                              <div className="page">
                                <Home {...this.state} />
                              </div>

                            {/* { Object.getOwnPropertyNames(this.state.currentShow).length !== 0 ? (

                            ) : (
                              <FallbackHome />
                            )} */}

                          </CSSTransition>
                        )}
                    </Route>
                    <Route exact path="/calendar" >
                        {({ match }) => (
                          <CSSTransition
                            in={match != null}
                            timeout={1000}
                            classNames="page"
                            unmountOnExit
                          >
                            <div className="page">
                              <Calendar />
                            </div>
                          </CSSTransition>
                        )}
                    </Route>
                  </main>
                  </div>
                  <AudioPlayer                   
                      toggleStream={this.toggleStream}
                      setPlayState={this.setPlayState}
                      handleAudioSourceError={this.handleAudioSourceError}
                      {...this.state} 
                    />
                  {/* { Object.getOwnPropertyNames(this.state.currentShow).length !== 0 && (

                  )} */}
                </div>
              </div>
            </div>
            

          </Router>
        </React.Fragment>
    );
  }
}

export default App;
