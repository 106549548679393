import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import $ from 'jquery';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';

import './AudioPlayer.scss';

class AudioPlayer extends Component {

  state = {
    titleElement: {},
    titleElementWidth: 0,
    titleWrapperElement: {},
    titleWrapperElementWidth: 0,
    slideTitle: false,
    showCurrent: 0,
    percentComplete: 0,
    showRunTime: {
      minutes: 0,
      seconds: 0
    },
    volume: "7",
    volumeOpen: false,
    pulseActive: true
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  titleCallback = el => this.setState({titleElement: el});
  titleWrapperCallback = el => this.setState({titleWrapperElement: el});

  getTitleWidth = () => {
    const titleElementWidth = this.state.titleElement.offsetWidth;
    this.setState({titleElementWidth});
    return titleElementWidth;
  }

  getTitleWrapperWidth = () => {
    const titleWrapperElementWidth = this.state.titleWrapperElement.offsetWidth;
    this.setState({titleWrapperElementWidth});
    return titleWrapperElementWidth;
  }

  handleAudioSourceError = () => {
    console.log('Switching audio source to public stream')
    this.props.handleAudioSourceError();
  }

  componentDidMount() {

    // Initialize Audio Visualizer 
    var ac = window.AudioContext || window.webkitAudioContext; 
    var context = new ac();
    var audioElement = document.querySelector(".player"); // generate from state
    const audSet  = this.props.audioSettings;
    audSet.element = audioElement;
    this.setState({audioSettings: audSet});
    var source = context.createMediaElementSource(audioElement);
    source.connect(context.destination);
    
    var analyser = context.createAnalyser();
    analyser.fftSize = 256;

    source.connect(analyser);
    analyser.connect(context.destination);

    var bufferLength = analyser.frequencyBinCount;

    var dataArray = new Uint8Array(bufferLength);
    analyser.getByteFrequencyData(dataArray);

    var canvas = document.getElementById("canvas");
    canvas.width = 1200;
    canvas.height = 80;
    var ctx = canvas.getContext("2d");

    var WIDTH = canvas.width;
    var HEIGHT = canvas.height;

    var barWidth = (WIDTH / bufferLength)/3;
    var barHeight = 1;
    var cutOff = 0;
    var gap = WIDTH / (bufferLength - cutOff);
    var x = 0;
    ctx.fillStyle = "#000";
    ctx.fillRect(0, 0, WIDTH, HEIGHT);

    function update() {
      requestAnimationFrame(update);
      x = 0;

      analyser.getByteFrequencyData(dataArray);

      ctx.fillStyle = "#000";
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      for (var i = 0; i < bufferLength; i++) {

        if (i >= cutOff ) {
          barHeight = (dataArray[i]/4)*1.5;

          var r = 0;
          var g = 118;
          var b = 255;

          ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
          ctx.fillRect(x, HEIGHT, barWidth, -barHeight);

          x += barWidth + gap*3;
        }
      }
    }

    update();

    // set the start and end time for the progress bar
    setInterval(() => {
      var now_raw = Date.now();
      var showStart = this.props.currentShow.startTime*1000;
      var duration = now_raw - showStart;
      var percentComplete = duration / (this.props.currentShow.runTime*1000);
      this.setState({ showCurrent: duration });
      this.setState({ percentComplete });
    }, 1000)

    // capture context so state + props are accessible
    const ctxt = this;

    // method to fade in audio using Tween.js when playback starts
    // const fadeInAudio = () => {

    //   Tween.get(audioElement)
    //   .to({ volume: 1 }, 7000, Ease.quadIn)
    //   .addEventListener("change", handleChange) 
    //   .call( () => { ctxt.setState({ volume: 1 }) })

    //   function handleChange(event) {
    //     if (event) { ctxt.setState({ volume: (event.currentTarget.rawPosition / 7000) })}
    //   }

    // }

    // initialize audio player visuals and audio element
    const init = () => {
      var ua = window.navigator.userAgent;
      var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      var webkit = !!ua.match(/WebKit/i);
      var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

      this.getTitleWidth();
      this.getTitleWrapperWidth();
      if (ctxt.state.titleWrapperElementWidth < ctxt.state.titleElementWidth) {
        this.setState({ slideTitle: true })
      }

      audioElement.volume = .7;
      
      // method to check if audio element is playing, 
      const playAudio = () => {
        // if audio not playing, resume the audio context
        if (audioElement.paused) {
          this.props.setPlayState("play");
          context.resume();
          if (!iOSSafari) {
            $(".player").trigger("play");
          }
        } else {
          // if audio is playing, fade in the audio and clear the interval
          // fadeInAudio();
          clearInterval(startAudio);
          this.props.setPlayState("pause");
          this.setState({ pulseActive: false})
        }
      }

      // start trying to play audio
      var startAudio = setInterval(playAudio, 500);

    }

    init();

  }

  changeVolume = (e) => {
    this.props.audioSettings.element.volume = e.target.value;
    this.setState({ volume: e.target.value });
  }

  render () {
    return (
      <div
        className={
          this.props.location.pathname === "/"
            ? "audio-player full"
            : "audio-player"
        }
      >
        <div className="audio-player-wrapper">
          <canvas id="canvas" />
          {/* remove progress bar if current show is empty */}
          {Object.entries(this.props.currentShow).length === 0 &&
          this.props.currentShow.constructor === Object ? (
            <React.Fragment />
          ) : (
            <div className="progress-bar">
              <div className="progress-bar-time">
                <div className="progress-bar-time-current">
                  {/* {this.state.showCurrent < 3600000 &&
                  this.state.showCurrent <
                    this.props.currentShow.runTime * 1000
                    ? moment(this.state.showCurrent).format("mm:ss")
                    : this.state.showCurrent > 3599000 &&
                      this.state.showCurrent <
                        this.props.currentShow.runTime * 1000
                    ? moment.utc(this.state.showCurrent).format("HH:mm:ss")
                    : moment
                        .utc(this.props.currentShow.runTime * 1000)
                        .format("HH:mm:ss")} */}
                    01:31:25
                </div>
                <div className="progress-bar-time-full">
                  {this.props.currentShow.runTime > 3599
                    ? moment
                        .utc(this.props.currentShow.runTime * 1000)
                        .format("HH:mm:ss")
                    : moment(this.props.currentShow.runTime * 1000).format(
                        "mm:ss"
                      )}
                </div>
              </div>
              <div className="progress-bar-timeline">
                <div className="progress-bar-timeline-full" />
                <div
                  className="progress-bar-timeline-current"
                  style={
                    this.state.percentComplete * 100 > 100
                      ? { width: `75%` }
                      : { width: `${this.state.percentComplete * 100}%` }
                  }
                />
              </div>
            </div>
          )}

          <div className="content-wrapper">
            <div className="stream-wrapper">
              <div
                ref={this.titleWrapperCallback}
                className="title-wrapper"
              >
                <div
                  ref={this.titleCallback}
                  className={
                    this.state.slideTitle ? "title slide" : "title no-slide"
                  }
                >
                  <span className="title-label">Currently Playing: </span>
                  {this.props.currentShow.songTitle ? (
                    <span className="title-value">
                      {this.props.currentShow.songTitle}
                    </span>
                  ) : (
                    this.props.currentShow.showTitle &&
                    this.props.currentShow.dj && (
                      <span className="title-value">
                        {this.props.currentShow.showTitle} by{" "}
                        {this.props.currentShow.dj}
                      </span>
                    )
                  )}
                </div>
              </div>
              <div className="subtitle-wrapper">
                {this.props.metaData.type === "LIVE" && (
                  <React.Fragment>
                    <span className="timecode">22:13</span> <span>|</span>
                  </React.Fragment>
                )}
                Current Listeners:{" "}
                {this.props.metaData.listeners ? (
                  <span className="active-listeners">
                    {this.props.metaData.listeners}
                  </span>
                ) : (
                  <span className="active-listeners">12</span>
                )}
              </div>
            </div>

            <div className="controls">
              <button
                className={
                  this.state.pulseActive
                    ? "control-btn playback-btn pulse"
                    : "control-btn playback-btn"
                }
                onClick={this.props.toggleStream}
              >          
                <CSSTransition
                  in={this.props.audioSettings.isPlaying}
                  timeout={400}
                  classNames="btn-anim"
                  unmountOnExit
                >
                  <div className="icon-container">
                    <img alt="Pause" src="./img/pause.svg" />
                  </div>
                </CSSTransition>
                <CSSTransition
                  in={!this.props.audioSettings.isPlaying}
                  timeout={400}
                  classNames="btn-anim"
                  unmountOnExit
                >
                  <div className="icon-container">
                    <img alt="Play" src="./img/play.svg" />
                  </div>
                </CSSTransition>
              </button>
              <button
                className="control-btn volume-btn"
                onClick={() => {
                  const v = !this.state.volumeOpen;
                  this.setState({ volumeOpen: v });
                }}
              >
                <CSSTransition
                  in={this.state.volumeOpen}
                  timeout={400}
                  classNames="btn-anim"
                  unmountOnExit
                >
                  <div className="icon-container">
                    <img alt="Close Volume Controls" src="./img/close.svg" />
                  </div>
                </CSSTransition>
                <CSSTransition
                  in={!this.state.volumeOpen}
                  timeout={400}
                  classNames="btn-anim"
                  unmountOnExit
                >
                  <div className="icon-container">
                    <img alt="Open Volume Controls" src="./img/volume.svg" />
                  </div>
                </CSSTransition>
              </button>
              <div
                className={
                  this.state.volumeOpen
                    ? "open volume-slider-wrapper"
                    : "volume-slider-wrapper"
                }
              >
                <label
                  htmlFor="volume-slider"
                  className="volume-slider-label"
                >
                  Volume
                </label>
                <input
                  id="volume-slider"
                  className="volume-slider"
                  type="range"
                  step=".1"
                  min="0"
                  max=".7"
                  value={this.state.volume}
                  onChange={this.changeVolume}
                />
              </div>
              <iframe title="iframeAudio" src="./autoplay.mp3" allow="autoplay" id="audio" style={{display: "none"}}></iframe>
              <audio
                crossOrigin="anonymous"
                className="player"
                controls={false}
                autoPlay={true}
                type="audio/mpeg"
                volume={this.state.volume}
              >
                <source
                  src={this.props.audioSettings.stream}
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AudioPlayer);
